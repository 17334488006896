import React from 'react'
import type { SvgProps } from 'react-native-svg'
import Svg, { G, Path } from 'react-native-svg'

const CartersLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 219 49" {...props}>
    <G fill="#00A9E0">
      <Path d="M23.4 25c-1.9-1.9-3.9-3.2-6.1-3.2-5.9 0-9.4 4.6-9.4 9.6s3.5 9.6 9.4 9.6c2.5 0 4.7-1.1 6.2-3l5.3 5.7a18 18 0 0 1-11.5 4.5C7.7 48.1 0 41.4 0 31.4s7.7-16.8 17.4-16.8c4.1 0 8.5 1.5 11.6 4.9L23.4 25zm23.8-10.4c-5.1 0-10 1.5-13.6 5.1l4.2 4.2c2.1-2 5.2-3.3 8.5-3.3 4.3 0 7.3 2.1 7.3 6v.9h-2c-8 0-20.1.7-20.1 11.1 0 6.3 5.7 9.6 11.6 9.6 4.3 0 8-1.6 10.2-5.2h.2v4.4h7.2V29c.1-4.6.1-14.4-13.5-14.4zm6 20.2c0 4.5-2.6 7.3-8.1 7.3-2.6 0-5.6-1.3-5.6-4.2 0-4.6 7.6-4.9 12-4.9h1.7v1.8zm13.2-19.4h8v5.1h.1a10.1 10.1 0 0 1 12.3-5.4v7.7c-1.3-.3-2.6-.6-3.9-.6-7.5 0-8.6 6.3-8.6 8.1v17.1h-8v-32zm24.4 6.8v-6.8h6.6V6.1h8v9.2h8.8v6.8h-8.8v14c0 3.2.9 5.2 4.5 5.2 1.4 0 3.3-.3 4.3-1V47a17 17 0 0 1-6.3 1.1c-8.5 0-10.4-3.8-10.4-11.3V22.2h-6.7zm58.8 9.8c0-11.1-6.1-17.4-15-17.4-9.6 0-17.4 6.7-17.4 16.8 0 10 7.7 16.8 17.4 16.8 5.1 0 9.8-1.8 13.5-6.4l-5.7-4.3c-2 2.5-4.6 4.3-8.6 4.3a8.3 8.3 0 0 1-8.6-7.6h24.4V32zm-24.4-3.8c.6-4.6 3.5-7.6 8.3-7.6 5 0 8.1 2.9 8.1 7.6h-16.4zm28.9-12.8h8v5.1h.1a10.2 10.2 0 0 1 12.4-5.4v7.7c-1.3-.3-2.6-.6-3.9-.6-7.5 0-8.6 6.3-8.6 8.1v17.1h-8v-32zm27.8-13.5h8.1l-5.1 15.9h-7.2zm23.5 22.3a7.3 7.3 0 0 0-6.3-3.3c-2.1 0-4.7 1-4.7 3.4 0 5.7 17.2 1.1 17.2 13.5 0 7.6-7.3 10.3-13.8 10.3-4.9 0-9.2-1.3-12.4-4.9l5.3-5c2.1 2.3 4.2 3.9 7.6 3.9 2.3 0 5.3-1.1 5.3-3.7 0-6.6-17.2-1.4-17.2-13.6 0-7.1 6.4-10.3 12.8-10.3 4.3 0 8.8 1.3 11.4 4.9l-5.2 4.8zm10.3-8.4a2.6 2.6 0 0 0-2.6 2.6c0 1.4 1.1 2.6 2.6 2.6 1.4 0 2.6-1.1 2.6-2.6s-1.2-2.6-2.6-2.6zm0 4.7a2 2 0 0 1-2.1-2.1 2 2 0 0 1 2.1-2.1c1.1 0 2.1.9 2.1 2.1a2 2 0 0 1-2.1 2.1z" />
      <Path d="M216.2 18.5c.4 0 .7-.3.7-.8 0-.6-.3-.9-1-.9h-1.2v2.9h.6v-1.2h.4l.7 1.2h.6l-.8-1.2zm-.9-.3v-.8h.4c.3 0 .7 0 .7.4s-.4.4-.7.4h-.4z" />
    </G>
  </Svg>
)

export default CartersLogo
