import AmericanEagleLogo from './AmericanEagle'
import AmericaninoLogo from './Americanino'
import AvemariaLogo from './Avemaria'
import BabyFreshLogo from './BabyFresh'
import BrissaLogo from './Brissa'
import CarolLogo from './Carol'
import CartersLogo from './Carters'
import ChevignonLogo from './Chevignon'
import CromanticLogo from './Cromantic'
import EspritLogo from './Esprit'
import FloripondiaLogo from './Floripondia'
import GefLogo from './Gef'
import KoajLogo from './Koaj'
import LiliPinkLogo from './LiliPink'
import MICLogo from './MIC'
import MoviesLogo from './Movies'
import OffcorssLogo from './Offcorss'
import PuntoBlancoLogo from './PuntoBlanco'
import RifleLogo from './Rifle'
import SevenSevenLogo from './SevenSeven'
import THMLogo from './THM'
import TennisLogo from './Tennis'
import TottoLogo from './Totto'

export const OUTLETS_LOGOS = {
  'AMERICAN EAGLE': AmericanEagleLogo,
  AMERICANINO: AmericaninoLogo,
  AVEMARÍA: AvemariaLogo,
  'BABY FRESH': BabyFreshLogo,
  BRISSA: BrissaLogo,
  CAROL: CarolLogo,
  "CARTER'S": CartersLogo,
  CHEVIGNON: ChevignonLogo,
  CROMANTIC: CromanticLogo,
  ESPRIT: EspritLogo,
  FLORIPONDIA: FloripondiaLogo,
  GEF: GefLogo,
  KOAJ: KoajLogo,
  'LILI PINK': LiliPinkLogo,
  MIC: MICLogo,
  MOVIES: MoviesLogo,
  OFFCORSS: OffcorssLogo,
  'PUNTO BLANCO': PuntoBlancoLogo,
  RIFLE: RifleLogo,
  'SEVEN SEVEN': SevenSevenLogo,
  TENNIS: TennisLogo,
  THM: THMLogo,
  TOTTO: TottoLogo,
}
