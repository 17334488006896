import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const THMLogo: React.FC<SvgProps> = (props) => (
  <Svg data-name="Capa 2" viewBox="0 0 167.8 54.7" {...props}>
    <G data-name="Capa 1">
      <Path d="M27.5 0c2.1.9 2.9 2.5 2.8 4.7V17c0 .8.2 1.3.9 1.9a19.2 19.2 0 0 1 7.3 20.5c-1.9 8-7 13-15 14.9A19.5 19.5 0 0 1 .2 37.9c-1-8 1.6-14.5 8-19.3.6-.4.6-.8.6-1.3V4.6C8.7 2.4 9.4 1 11.5 0h1.7c2.2.5 3.1 1.8 3.2 4.2v15.5c0 2-.8 3.4-2.6 4.4-4.2 2.4-6.3 6-6.3 10.8 0 4.8 2.3 8.6 6.6 10.8a11 11 0 0 0 12.2-1c3.9-2.7 5.6-6.5 5.1-11.2-.4-4.2-2.5-7.3-6.2-9.4a4.8 4.8 0 0 1-2.6-4.6V3.8C23 1.6 23.9.5 26 0h1.6Zm53.7 0c2.6 1 3.2 2 3.2 5v14.5c0 1 .1 1.2 1 .7 10.4-5 22.4 2.4 22.4 14v16.6c0 2-1.3 3.5-3.1 3.8-1.7.3-3.6-.7-4.2-2.3-.3-.6-.3-1.3-.3-2v-16a7.9 7.9 0 1 0-15.8.1V50c0 2.9-1.5 4.6-3.8 4.6-2.4 0-3.8-1.7-3.8-4.6V5.2c0-2.5.6-4.3 3-5.2h1.4Zm59.3 23.9a15.6 15.6 0 0 1 27.3 9.9v16.8c0 2.5-1.7 4-4 4-2.2 0-3.7-1.6-3.7-4.1V34c0-4-3-7.2-7-7.7a8 8 0 0 0-8.8 7.8v16.3c0 2.6-1.5 4.2-3.8 4.3-2.3 0-4-1.7-4-4.3l.2-15c0-2.1-.3-4.2-1.6-6a7.8 7.8 0 0 0-8.9-2.7 7.9 7.9 0 0 0-5.3 7.7v16.3c0 2.8-2.6 4.6-5.2 3.7a3.6 3.6 0 0 1-2.5-3.5c0-6.2-.3-12.4.1-18.5.5-8.3 9-15 17.4-13.7 3.9.6 7.1 2.3 9.8 5.2ZM59.1 11.1V18c0 .7.2.9.9.9h9c2 0 3.5 1.1 3.9 3 .4 1.6-.4 3.3-1.9 4.1a4 4 0 0 1-2.2.5H60c-.7 0-.9.2-.9.8v11.5c.1 4.4 4 8 8.8 8.3 2.4.2 4 1.8 4 4-.3 2.3-2.2 3.7-4.7 3.6-7.5-.3-14.6-6-15.6-14.5-.2-2.2 0-4.4-.1-6.6v-6c0-1-.3-1.2-1.2-1.1h-1.6c-2-.2-3.5-1.8-3.5-3.8 0-2 1.3-3.5 3.3-3.8h.6c.8 0 1.8.2 2.2-.2.5-.5.2-1.5.2-2.3V5c0-2.5 1.5-4.2 3.8-4.2s3.9 1.7 3.9 4.2v6.2Z" />
      <Path
        fill="#d23440"
        d="M13.3 34.8c0-3.5 2.8-6.3 6.2-6.3a6 6 0 0 1 6.1 6.3c0 3.6-2.7 6.3-6.2 6.3a6.1 6.1 0 0 1-6-6.3Z"
      />
    </G>
  </Svg>
)

export default THMLogo
