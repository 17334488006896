import * as React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const FloripondiaLogo: React.FC<SvgProps> = (props) => {
  return (
    <Svg data-name="Capa 2" viewBox="0 0 61.12 12.67" {...props}>
      <G data-name="Capa 1">
        <Path
          fill="#ea93ad"
          d="m60.51 2.36.05.01c.34.06.47.32.57.61v.56c-.07.04.03.21-.13.19-.06-.05-.08-.13-.1-.2.03-.44-.17-.76-.52-1-.02-.07.01-.12.06-.17.03-.02.06-.02.08 0Z"
        />
        <Path
          fill="#ee83a5"
          d="M52.18 2.73h.05c.07.42.19.84.07 1.26-.04.15.04.28.05.42.03.31-.06.61-.08.91-.03.46.08.91.08 1.38 0 .66-.05 1.32-.07 1.97-.01.32.11.63.07.95-.02.18 0 .37-.17.5-.06.05-.12.06-.18 0h-1.23c-.12 0-.17 0-.19-.12-.09-.2-.1-.42-.1-.63V3.25c0-.19-.04-.4.16-.53h.06a.71.71 0 0 1 .38-.09c.37.01.74 0 1.1.09Z"
        />
        <Path
          fill="#ee83a4"
          d="M22.33 2.74h.04c.11.13.11.28.11.43 0 2.16.02 4.31-.01 6.47 0 .18.08.38-.14.49a2.9 2.9 0 0 1-1.6 0 .46.46 0 0 1-.1-.34c.04-.41.02-.82 0-1.23-.02-.34.05-.67.03-1.01-.05-.96 0-1.93 0-2.89 0-.55-.07-1.1 0-1.65.03-.25.13-.35.36-.36.44-.02.88-.03 1.31.1Zm5.6 7.27h-.12c-.18.13-.39.09-.59.14-.08.02-.14-.02-.17-.1-.05.04.07-.12-.04-.03-.16.13-.58.04-.68-.13-.05-.08-.01-.14.05-.18.12-.08.25-.09.37.01-.01-.07-.09-.06-.13-.1-.13-.11-.11-.22.05-.28.14-.05.26-.16.39-.17.22-.02.18-.14.16-.27.41-.78.52-1.62.5-2.48a5.57 5.57 0 0 0-.34-2.02.54.54 0 0 1-.05-.21c0-.11.04-.22.1-.31.08-.1.17-.18.31-.14.1.03.12.08.11.32-.02-.28.13-.37.37-.36.14 0 .23-.05.27-.19.03-.1.09-.16.2-.14.42.36.56.88.66 1.39.18.99.16 1.99.03 2.98-.1.73-.36 1.43-.93 1.97a.98.98 0 0 1-.52.3Z"
        />
        <Path
          fill="#ef83a5"
          d="m13.69 3.42.2.3a.3.3 0 0 1 .05.07c.03.07.05.17-.03.18-.16.03-.21.14-.26.27-.04.11-.12.11-.23.08-.13-.03-.27-.01-.29.14-.02.14.03.3.15.38.11.08.13-.04.15-.11.02-.05.02-.13.1-.13.07 0 .07.08.11.13.06.09.03.31.12.27.19-.1.51.16.58-.21 0-.03.05-.04.08-.04.14.02.09.14.12.22.24.64.32 1.3.13 1.97l-.01.18c-.02.21.04.44-.13.62-.05.42-.23.79-.51 1.11l-.1.13a.6.6 0 0 1-.4.44c-.31-.05-.49-.37-.34-.62.28-.45.2-.89 0-1.34.01.12.03.23.02.35-.01.09 0 .22-.1.23-.11 0-.11-.12-.12-.21a.84.84 0 0 0-.04-.27c-.05-.47-.01-.94-.02-1.41a8.67 8.67 0 0 0-.28-1.97c-.08-.3-.2-.58-.39-.83-.35-.43-.86-.43-1.2 0a3.1 3.1 0 0 0-.54 1.61c-.02.12 0 .25-.11.34-.06 0-.1.02-.09.08.06.26-.14.48-.13.74 0 .12-.13.21-.27.2-.15 0-.18-.12-.19-.24-.02-.29 0-.57 0-.86 0-.09.03-.21-.04-.27-.27-.22-.15-.41.03-.61-.06-.17.02-.35-.1-.51-.05-.06 0-.14.05-.19.07-.09.13-.18.18-.28.72-.72 1.97-1.08 2.97-.55.11.06.26.06.33.2l.19.11c.16.06.31.14.37.32Z"
        />
        <Path
          fill="#ee83a4"
          d="m58.02 4.28.08.18c.13.58.31 1.16.22 1.77 0 .3.08.59.09.88.01.2.17.47-.15.62-.06.03 0 .17 0 .26 0 .17-.02.34.02.56.07-.19-.01-.33.02-.46.02-.08.05-.17.15-.16.09 0 .09.1.09.17.03.6.18 1.19.19 1.79 0 .1 0 .16-.11.18-.43.06-.86.02-1.29.04-.15 0-.16-.14-.17-.25-.02-.17.02-.35-.05-.52-.08-.66-.18-1.32-.2-1.98 0-.24.05-.5-.18-.68-.03-.02-.05-.08-.04-.11.07-.34-.03-.7.15-1.03.25.1.51.16.79.14.38-.02.4-.03.39-.41 0-.08 0-.16-.07-.31.03.16.04.25 0 .33-.07.11-.18.07-.28.07h-.21c-.19.01-.35 0-.36-.25-.01-.28.04-.38.32-.41.28-.03.38-.3.61-.41Z"
        />
        <Path
          fill="#ee84a5"
          d="m18.01 3.59.06-.07c.24-.26.4-.61.8-.67.06-.08.17-.08.25-.12.17-.13.34-.2.54-.06.12.43.05.87.06 1.31 0 .16-.09.21-.24.21-.17 0-.32-.04-.49-.04-.75.01-1.18.46-1.45 1.09-.19.44-.25.9-.26 1.38-.01 1.07.04 2.13-.03 3.2 0 .12.02.26-.14.31H17c-.41.11-.84.04-1.25.09-.19.02-.3-.1-.32-.3l-.01-.37v-6.1l.03-.46c0-.28.15-.36.38-.36.36 0 .71.05 1.07.01.16-.02.28.07.28.25a.18.18 0 0 1-.09.06c-.38 0-.76-.04-1.15-.08.11.04.26-.06.33.09.05.1 0 .2-.07.28-.13.14-.29.13-.47.05.08.32.07.63.02.94-.07.37.05.73.04 1.1-.02 1.45.03 2.89-.03 4.34 0 .15.05.18.18.18h.34c.25.02.46 0 .41-.34-.01-.08.01-.17.11-.2.08-.02.14 0 .13.1-.03-.85-.03-1.7 0-2.54.01-.34.13-.68.13-1.03 0-.09.02-.18-.01-.27l.01-.09c.17-.27.2-.59.33-.88.1-.22.2-.44.36-.63.05-.14.15-.25.24-.37Z"
        />
        <Path
          fill="#ef84a6"
          d="M58.02 4.28c-.17.23-.28.53-.65.49-.26-.03-.17.19-.19.32-.03.19.07.18.22.17.17-.02.37.05.49-.02.11-.07-.03-.31.08-.46.17.24.13.5.14.75 0 .16-.06.22-.22.21-.2 0-.41-.02-.61 0-.21.02-.34-.05-.4-.25-.03.02-.05.02-.08 0-.09-.14-.05-.29-.06-.44 0-.13 0-.27-.14-.33-.15-.14-.15-.35-.2-.53-.14-.12-.23-.28-.34-.42-.13-.19-.3-.25-.53-.24-.18.01-.35 0-.38-.25-.04-.3.01-.44.25-.54.49-.19.98-.18 1.47.02.08.03.17.08.26.07.1 0 .18.05.22.15.05.12-.03.22-.06.32-.04.12-.06.24.03.35.15-.08-.1-.53.32-.36.17.08.16.26.22.4.19.12.12.33.18.5l-.02.07Z"
        />
        <Path
          fill="#ef83a5"
          d="M45.02 2.84c.89-.36 1.55-.21 2.1.5.09.21-.02.29-.2.33-.07 0-.14 0-.21-.02-.41-.11-.72.05-.96.37-.06.07-.1.16-.17.21-.15.09-.19.25-.21.39-.04.23-.21.25-.32.16-.2-.16-.44-.18-.66-.26-.03-.01-.05-.04-.06-.06-.04-.29-.03-.58-.09-.86.05-.25.27-.35.43-.5.06-.07.09-.16.19-.18.04-.07.12-.05.18-.08Z"
        />
        <Path
          fill="#ee84a6"
          d="M21.22.13c.39-.1.76-.06 1.03.27.24.28.27.61.14.95-.13.36-.43.56-.83.57a.89.89 0 0 1-.84-.51A1 1 0 0 1 20.85.4l.19-.2.17-.07Z"
        />
        <Path
          fill="#ef84a6"
          d="m50.84.27.04-.07a.86.86 0 0 1 1-.08c.36.19.55.55.48.94-.03.17-.03.37-.18.5-.03.07-.06.14-.13.18-.3.24-.61.41-.98.14a.3.3 0 0 1-.26-.13.23.23 0 0 1-.1-.12c-.04-.11-.08-.22-.19-.29-.05-.06-.03-.13-.03-.2.04-.15 0-.31.1-.45.04-.06.05-.19.15-.16.11.03.09.15.09.24 0 .12-.05.24-.04.37 0 .07.03.14.08.25.07-.14.07-.29.19-.37.08-.05 0-.1-.03-.15-.11-.18-.24-.35-.19-.58Z"
        />
        <Path
          fill="#ee83a4"
          d="M40.58 3.84c-.34-.23-.81-.17-1.15.12-.4.35-.53.83-.69 1.3-.25-.07-.22-.24-.15-.42.15-.2.12-.46.24-.67.07-.12.09-.27.22-.35.31-.06.58-.23.88-.33l.08-.02h.12c.09 0 .22.09.27-.04.05-.13-.04-.23-.15-.3-.05-.03-.11-.05-.14-.11 0-.3.19-.34.43-.33.24.18.22.43.17.69 0 .04.07.02.03.02v-.02c0-.21.16-.25.29-.19.31.13.4-.02.44-.28.13-.14.26-.12.39 0 0 .07.04.11.11.11.09.05.17.11.16.23 0 .04 0 0-.01.03-.1.14.25.32-.02.4-.16.05-.23.2-.39.23-.12.03-.2.1-.21.23-.01.21-.12.32-.37.35.33.23.31.56.35.88.02.16.03.31-.12.42-.21.06-.33-.01-.29-.24.04-.2-.04-.4 0-.61 0-.02.08-.13-.05-.11-.17-.09 0-.27-.1-.39-.1-.09-.07-.24-.13-.35l-.06-.07c-.06-.02-.09-.08-.12-.12l-.07-.06Z"
        />
        <Path
          fill="#ef84a6"
          d="M31.46 9.63c-.31-.31-.62-.62-.82-1.02-.05-.1-.11-.2-.12-.31-.03-.05 0-.08.05-.1.23 0 .44-.11.64.17.13.18.46-.02.48-.26v-.03c.02-.31.03-.31.3-.17.1.06.09.17.11.27.06.3.16.59.26.88.12.36.43.55.66.82-.14.28-.43.17-.65.26a.9.9 0 0 1-.54-.22l-.18-.13c-.07-.03-.15-.04-.18-.13Z"
        />
        <Path
          fill="#ed87a6"
          d="M25.66 9.27c-.19-.35-.33-.72-.49-1.1v4.21c0 .23-.07.3-.3.29-.42-.02-.84-.01-1.26-.02 0-.04 0-.07.04-.09.27-.09.55-.04.82-.05.46 0 .46 0 .46-.48V9.7c0-.68-.05-1.36.08-2.05.13-.65.02-1.33.04-2 0-.15-.05-.34.17-.41.17.19.02.41.07.61 0 .78-.04 1.56.19 2.32.06.22.15.42.28.61.33.46.76.55 1.26.26.07-.04.13-.1.22-.08 0 .05.02.1.08.12.06.02.12.05.07.13-.03.05-.08.05-.13.03-.27-.11-.4.24-.65.2-.03 0-.02.1.05.13l.21.12c.06.04.1.09.04.15-.05.06-.12.04-.16-.01-.13-.18-.26-.15-.41-.02q.42.28.83 0c.06.04.04.09 0 .12-.05.04-.12.07-.06.14.04.05.09.03.14 0 .18-.07.38-.03.56-.06l-.12.12c-.63.2-1.32.05-1.67-.37l-.06-.06c-.12-.13-.3-.22-.31-.43Z"
        />
        <Path
          fill="#ec85a3"
          d="M23.63 12.6v.05c-.12-.07-.06-.19-.06-.28l-.01-9.3c0-.28.09-.35.35-.33.4.02.8 0 1.2 0h.05c.14.12.11.28.11.43.02.59-.03 1.18.01 1.77 0 .1-.03.19-.14.19-.09 0-.13-.09-.16-.17-.07-.21-.1-.42-.06-.64.06-.38.03-.76.04-1.14 0-.17-.06-.25-.23-.24-.23 0-.47 0-.76-.03.1.05.2.07.18.11-.11.19.07.34.05.51-.01.11-.05.21-.16.24-.24.05-.25.23-.25.42v8.09c0 .12-.02.24-.15.31Z"
        />
        <Path
          fill="#e787a4"
          d="M5.18.27v1.54c-.05 0-.1 0-.14-.05v-.49c0-.25.02-.5 0-.75L4.99.46c-.36.05-.71-.11-1.07 0l-.1.02C3 .44 2.19.43 1.37.43c-.11 0-.23 0-.33.03l-.1.02C.72.52.48.43.27.53.21.64.23.75.23.86v8.73c0 .35 0 .35.35.35h1.05c.06 0 .11.03.15.08v.06l-.05.05c-.49.13-1 .13-1.5.1a.22.22 0 0 1-.22-.15v-.06L0 .84Q0 .26.58.26h4.59Z"
        />
        <Path
          fill="#ee83a4"
          d="m6.24 10.12-.01-.86V.38c0-.27.05-.39.35-.37.4.03.8 0 1.2 0l.05.01c.07.08.04.18.04.27v9.57c0 .09.03.19-.04.27s-.07 0-.08-.05c-.08-.24-.07-.48-.07-.73V.71c0-.49 0-.49-.49-.49h-.15q-.66 0-.66.65v8.84c0 .19.12.21.27.21h.85c.12 0 .3 0 .2.23a.32.32 0 0 1-.23.06c-.41-.03-.83-.05-1.24-.1Z"
        />
        <Path
          fill="#ed86a5"
          d="M57.13 2.91s-.12.06-.14.05c-.36-.35-.82-.19-1.23-.24a.54.54 0 0 0-.57.45c-.02.24.07.34.31.31.28-.04.5.03.62.32-.07.05-.14.02-.2-.01a1.3 1.3 0 0 0-1.69.51c-.2.32-.37.65-.44 1.02-.02.1-.05.19-.17.18-.14-.01-.14-.13-.14-.23 0-.22.09-.41.19-.6.1-.77.51-1.33 1.18-1.72.31-.33.72-.35 1.13-.39.31-.03.64-.03.9.2h.04c.09.04.22.02.22.17Z"
        />
        <Path
          fill="#ed86a3"
          d="m57.12 10-.01-.6c-.02-.07 0-.08.07-.07.12.17.05.36.06.53 0 .14.03.17.2.16.41-.03.82 0 1.17 0 .06-.71-.16-1.37-.14-2.08-.18.12-.07.24-.07.36 0 .14.1.37-.09.39-.25.03-.13-.23-.14-.35-.02-.28 0-.57 0-.86.17-.02.08.15.17.18l.01-.08a2.7 2.7 0 0 0-.08-.9.86.86 0 0 1 .03-.45c.02-.01.04 0 .05.02.12.58.24 1.15.24 1.75 0 .1-.04.2 0 .3v.05c.12.5.21 1.01.25 1.53 0 .07-.01.14-.07.19-.09.06-.19.05-.29.06-.41.18-.82.1-1.23.04-.09-.01-.09-.1-.12-.16Z"
        />
        <Path
          fill="#ed8baa"
          d="m57.18 9.33-.07.07c-.11-.48-.1-.98-.17-1.47-.04-.29-.05-.59-.08-.88-.03-.1-.05-.21-.16-.26a.18.18 0 0 1-.05-.07.65.65 0 0 1-.02-.39c.06-.26.04-.5-.03-.78-.39.26-.76.53-1.1.85-.14.13-.25.3-.44.37-.25-.05-.04-.26-.13-.36.05-.2.23-.28.38-.37.21-.13.41-.29.64-.4.05-.11.16-.13.26-.18l.17-.14c.05-.07.11-.11.2-.1.18.01.19.17.24.29h.06l-.11.97c0 .06-.03.14.03.17.27.12.2.35.2.56 0 .72.13 1.42.19 2.13Z"
        />
        <Path
          fill="#ec8fac"
          d="m61 3.71-.13.24-.05.03c-.09.19-.27.2-.44.23-.4.17-.79-.02-1.04-.5a1.22 1.22 0 0 0-.07-.17.81.81 0 0 1 .05-.68l.02-.06c.14-.42.58-.62 1.03-.47.02.23-.23.1-.29.22-.31.15-.57.34-.55.74.01.29.15.49.4.6.26.11.5.07.71-.13.09-.08.14-.21.28-.22.08.04.07.11.07.18Z"
        />
        <Path
          fill="#ec88a6"
          d="M39.47 3.29c.1-.19.3-.27.44-.42.05-.1.17-.08.25-.13.28-.19.58-.29.92-.13l.18.01c.07-.01.13 0 .19.05h.05c.48.08.76.32.82.69-.14.06-.16-.05-.19-.13l-.19-.19c-.02-.02-.06-.02-.06-.06-.11-.12-.23-.15-.37-.06-.28-.2-.64 0-.93-.18-.2.02-.4.04-.43.31-.12.04-.16.11-.12.23.04.1.05.2-.07.26-.14.05-.31.07-.32-.1 0-.18-.13-.09-.18-.14Z"
        />
        <Path
          fill="#ee85a5"
          d="M50.52 1.12c-.06-.24.04-.46.11-.68.04-.1.15-.11.21-.18.15.17.08.47.35.57.09.03.09.17 0 .19-.29.07-.18.31-.23.49q-.15-.08-.23-.95c-.14.16-.13.37-.22.55Z"
        />
        <Path
          fill="#ed86a4"
          d="M55.15 10.01c-.31.21-.66.16-1 .12l-.18-.01a.18.18 0 0 0-.13-.12c-.11-.02-.21-.07-.24-.19l-.08-.18c.34-.02.68-.05 1.02-.01.08 0 .17.03.18.13 0 .11-.08.16-.17.19-.15.05-.31.02-.44.03.33.07.68-.12 1.03.04Z"
        />
        <Path
          fill="#ec88a6"
          d="M9.31 4.03c.18-.21.31-.47.55-.62 0 .08-.04.13-.09.19-.06.07-.26.14 0 .25.07.04-.08.28.03.43-.01.02-.04.04-.05.03-.37-.19-.45.12-.58.34-.04.06-.07.16-.18.12 0-.21.17-.35.19-.55l.13-.18Z"
        />
        <Path
          fill="#eb88a7"
          d="M25.89 3.41c.06-.18.24-.27.34-.42.02 0 .03 0 .04-.02.03-.01.05 0 .07.01.02.08-.03.13-.07.19l-.06.06c-.11.07-.16.24-.33.18Z"
        />
        <Path
          fill="#fefefe"
          d="M18.01 3.59a.85.85 0 0 1-.24.38c-.03-.2.11-.29.24-.38Zm8.39-.74c.02-.16.15-.11.24-.13 0 .15-.11.16-.22.15l-.03-.03Zm-7.28-.11a.49.49 0 0 1-.25.12c.02-.17.15-.11.25-.12ZM9.98 9.65c-.13.05-.17-.02-.18-.13h.01c.12-.05.19-.01.17.12Zm21.48-.02.18.13c-.14.07-.18 0-.18-.13Z"
        />
        <Path fill="#fefefe" d="M26.33 2.96h-.06c-.03-.11-.04-.21.12-.11h.01c0 .07-.02.1-.07.11Z" />
        <Path fill="#de8fa6" d="M60.37 2.49h-.29c.06-.14.23-.02.31-.12h.06c0 .06-.02.1-.07.13Z" />
        <Path
          fill="#ef84a6"
          d="M27.38 4.15s-.02 0-.02-.02c-.51-.8-1.3-.66-1.75.14-.05.03-.03.15-.11.11-.1-.05-.05-.15-.02-.24.09-.27.21-.53.41-.74.14-.03.2-.19.33-.24.03.03.04.07.01.11 0 .02-.02.03-.04.03.04.02.03-.04.06-.04.16-.1.26.08.39.09-.08-.2-.26-.17-.42-.18l.12-.2.07-.1a.47.47 0 0 0 .24-.13c.28-.03.54-.13.84-.08.52.08.9.34 1.19.76-.13.29-.33.41-.66.4-.15 0-.02.28-.11.42-.1 0-.16-.09-.25-.13.02-.1.2-.1.15-.24-.02-.06-.06-.08-.11-.08-.12 0-.18.1-.23.18-.04.06-.01.15-.09.19Z"
        />
        <Path
          fill="#ee83a4"
          d="m12.9 8.41.13-.86c.1.12 0 .28.1.48v-.65c.4.1.34.41.32.68-.02.26.13.56-.17.76-.08.06-.11.18-.04.25.1.11.11.35.36.28a2.67 2.67 0 0 1-3.36.44L10 9.65a.33.33 0 0 0-.17-.14l-.43-.49a4.77 4.77 0 0 1-.39-4.25c.14-.11.16-.3.26-.44.11-.15.22-.29.41-.09.03.03.09.02.14.03.08.23-.21.21-.24.36-.03.18.28.11.25.36-.05.34-.01.69-.01 1.04 0 .07-.02.15.06.19.08.04.13 0 .19-.05.06-.06.12-.12.03-.2-.02-.02-.04-.1-.03-.11.29-.2.1-.55.23-.81.1.07.06.15.07.22-.01 1.07 0 2.15.25 3.2.1.44.24.87.59 1.19.33.31.72.32 1.08.03.36-.29.44-.72.61-1.11.09.08.03.16.03.18.01-.12.3-.11.28-.32.03-.03-.05-.02 0 0-.12.21-.2.04-.29-.03Z"
        />
        <Path
          fill="#ed84a5"
          d="M13.88 3.77c.34.25.44.62.56.99-.06.04-.07.1-.07.16.01.17-.04.24-.21.14-.19-.12-.35.02-.52.11-.01-.2-.04-.37-.11-.54-.09.11.05.34-.16.35-.21.01-.25-.18-.29-.34-.03-.11-.15-.28-.02-.33.12-.04.25-.26.43-.08.1.11.11-.04.12-.07.02-.17.03-.3.27-.25.07.02-.01-.09 0-.15Z"
        />
        <Path
          fill="#ee83a4"
          d="M47.81.26c-.05-.2.04-.25.23-.25h1.11c.23-.01.27.08.27.29v9.49c0 .27-.06.37-.34.35a5.02 5.02 0 0 0-.95 0c-.29.02-.34-.09-.33-.35.02-.62 0-1.23 0-1.85-.17-.17-.15-.38-.11-.58.12-.27.1-.56.09-.84-.02-.49.08-1-.1-1.48-.05-.13 0-.28.12-.39v-.17s.06-.02.09 0c0 .05.02.1.01.12 0-.13-.07-.3-.02-.45.03-.1.02-.31.24-.16.07.05.09-.01.09-.06 0-.11.02-.25-.13-.27-.27-.04-.23-.2-.18-.38.33-.32.33-.72.31-1.13v-.8c0-.18-.02-.33-.15-.48-.18-.2-.11-.46.1-.55-.11-.05-.23.08-.35-.02Zm-3.09 9.62a3.67 3.67 0 0 1-1.13-2.44c0-.05-.03-.09-.05-.14.01-.59-.07-1.19.06-1.78q.13.13.08.35a.7.7 0 0 0 .08-.29c.01-.14-.02-.32.17-.35.18-.04.36.05.45.21.09.17.19.24.38.25.2 0 .31.1.25.32a.57.57 0 0 0 0 .26c0 .04.06-.02.02 0L45 6.26c.02-.08-.05-.22.06-.24.13-.02.15.11.17.22.02.16-.01.33.04.49.05.41.08.82.18 1.21.07.27.17.52.32.76.2.32.49.45.86.44.27.03.43-.14.56-.34a2.5 2.5 0 0 1 .17-.24c.04-.04.08-.11.14-.05.05.04.03.11 0 .16-.07.05-.05.12-.07.19-.06.07-.07.16-.12.24-.52 1.07-1.46 1.36-2.58.8Z"
        />
        <Path
          fill="#ee82a2"
          d="M47.86 4.46h-.06V.27c.21-.1.41.01.62.04-.24 0-.45.03-.38.35 0 .04 0 .11.03.11.34.11.2.4.21.61.02.39-.03.78.02 1.16.04.36-.16.55-.38.74-.07-.38-.02-.76-.03-1.13V.7c.04.85-.06 1.93.04 3.02.02.24-.04.49-.08.73Z"
        />
        <Path
          fill="#ec8ba9"
          d="M47.8 4.63c0 .75.12 1.49.07 2.24-.01.17 0 .35-.12.49a.23.23 0 0 1-.08-.14c-.03-.5 0-1-.01-1.5a4.09 4.09 0 0 0-.24-1.29c-.1-.25-.23-.48-.45-.64-.06-.04-.11-.09-.1-.18.18 0 .27-.08.26-.26.28.28.41.63.54.99.04.1.04.22.13.3Z"
        />
        <Path
          fill="#eb91ac"
          d="M47.73 7.22v.14c-.02.19.01.38.05.57-.16.21-.1.52-.3.72l-.01-.15c-.11.08-.1.24-.24.27-.07-.1-.01-.2.03-.28.15-.28.22-.59.29-.89.07-.12-.02-.31.17-.37Zm-.42 1.85c0-.1.02-.19.12-.24 0 .1-.04.18-.12.24Z"
        />
        <Path
          fill="#ec85a2"
          d="M57.89 9.95h-.31c-.07 0-.15 0-.15-.11 0-.07.05-.12.11-.13.26 0 .53 0 .79.02.05 0 .06.05.06.09 0 .07-.03.12-.11.12h-.4Z"
        />
        <Path
          fill="#ee83a4"
          d="M17.08 5.51c.08.09.24.15.08.3-.02.02 0 .08-.02.12-.17 1.13-.12 2.26-.12 3.39v.19c-.1-.01-.08-.21-.2-.14-.09.05-.06.18-.04.25.09.27 0 .34-.27.33-.28-.02-.57 0-.87 0 .15-.68.07-1.33.08-1.99V5.31c0-.49-.1-.97 0-1.47a.73.73 0 0 0-.09-.57c-.04-.07-.04-.15.04-.19.11-.06.1.04.13.11.06.15.19.1.29.08.14-.02.14-.16.16-.27.02-.08-.07-.08-.13-.09-.11-.01-.22.03-.33-.05.43.02.86-.09 1.29.05.04.86.06 1.73 0 2.59Z"
        />
        <Path
          fill="#df87a3"
          d="M17.08 5.51c-.03-.8.05-1.61-.04-2.41 0-.06.03-.12.04-.18h.07V5.5h-.08Z"
        />
        <Path
          fill="#ee83a4"
          d="M31.95 7.91c-.01.06.01.15-.06.15-.13 0-.02-.17-.12-.21v.13c0 .53 0 .5-.54.51-.24 0-.07-.28-.27-.27-.12 0-.24.21-.36 0-.26-.35-.39-.76-.28-1.15.1-.36.13-.7.13-1.05l.02-.31c-.02.05-.03.1-.09.1s-.08-.06-.08-.11c0-.16 0-.33.07-.48.05-.1.05-.22.06-.33.09-.38.31-.7.49-1.05a2.89 2.89 0 0 1 2.39-1.23c.19 0 .38.03.57.1.2.1.44.12.65.31-.26.14-.54.1-.79.19-.04 0-.08-.02-.12-.04-.54-.37-1.06-.08-1.29.47-.37.9-.45 1.85-.38 2.81.03.4 0 .8.08 1.2.02.09 0 .19-.07.26Z"
        />
        <Path
          fill="#ed86a3"
          d="M30.42 4.9c.09.17-.01.32-.07.48-.04.11 0 .24 0 .41.1-.13.01-.27.17-.38 0 .45-.02.87 0 1.28.01.22-.18.33-.18.53 0 .36.15.67.25 1l-.07.08a4.36 4.36 0 0 1-.33-2.39c.03-.34.07-.69.23-1.01Z"
        />
        <Path
          fill="#ee83a4"
          d="M.92.39h.06c.48-.08.63.04.64.51 0 .23.09.44.14.66 0 .9.02 1.81.01 2.71 0 .26.07.33.33.33.82-.02 1.64 0 2.47 0 .1 0 .24-.07.27.11-.39.11-.79.1-1.18.08-.43-.02-.86 0-1.19-.02.61-.04 1.31.07 2.02 0 .12-.01.25-.04.35.06-.08.12-.21.06-.31.06H2.07c-.23 0-.29.07-.29.3v4.49l-.02.34h-.1c-.4-.07-.8-.04-1.19-.02-.27.01-.32-.07-.32-.32V1.37c.01-.03.02-.07.05-.07.35-.04.43-.27.39-.58C.56.47.74.43.92.4Z"
        />
        <Path
          fill="#ee85a6"
          d="M5.12 1.25c-.03.17.07.35-.08.5-.4-.45-.82-.89-1.41-1.1-.14-.3.15-.19.25-.26.25-.03.5-.11.68.16.05.07.08-.02.13-.03.13-.02.22-.14.36-.13.08.28-.02.58.06.86Z"
        />
        <Path
          fill="#ed85a3"
          d="M3.88.39c-.09.09-.3.05-.25.26A6.73 6.73 0 0 0 2.08.51c-.25 0-.32.08-.3.32.01.24 0 .49-.01.74a.78.78 0 0 1-.22-.62C1.56.48 1.48.4 1 .4c.32-.15.66-.02.98-.05.64-.06 1.28.03 1.91.05Z"
        />
        <Path fill="#ef81a6" d="M4.84 4.83H1.95v-.11c.96-.05 1.93.05 2.89-.02v.12Z" />
        <Path
          fill="#ed84a2"
          d="M57.3 3.03c.13.08.23.18.3.32-.23-.07-.16.12-.17.21-.01.09.11.24-.09.25-.16 0-.15-.14-.18-.27-.04-.2.13-.33.13-.51Z"
        />
        <Path
          fill="#ee83a4"
          d="M56.54 5.25c.15-.16.06-.36.07-.54.18.05.18.05.19.21v.58c-.07-.1-.12-.23-.26-.25ZM35.02 9.44a.52.52 0 0 0-.24.2 4.8 4.8 0 0 1-.99.49c-.47.05-.95.12-1.41-.06.19-.11.44-.03.62-.18a.24.24 0 0 1 .19-.08c.41-.02.65-.25.82-.6.17-.34.26-.69.31-1.06.2-1.29.11-2.57-.16-3.84-.03-.12-.06-.24-.07-.36a.94.94 0 0 0-.08-.36c-.06-.15-.02-.3.13-.36.18-.08.37-.1.57-.13.61.35.96.91 1.18 1.55.51 1.5.4 2.94-.47 4.29-.12.18-.26.34-.4.51Z"
        />
        <Path
          fill="#ec88a5"
          d="M34.71 3.1s-.16-.03-.12.07c.04.12.01.11-.08.1-.43-.05-.49.03-.36.44.02.06 0 .14.01.21-.27-.18-.35-.47-.44-.75.2-.17.45-.11.7-.14-.16-.18-.41-.16-.56-.31.32.05.61.15.85.39Z"
        />
        <Path
          fill="#ef84a6"
          d="m53.92 7.3.24-.19c.13-.01.25.07.39.01.11-.05.18.03.14.12-.13.34-.03.69-.1 1.03 0 .02-.02.04-.03.05-.18.07-.18-.11-.25-.18-.12.28-.18.31-.46.16-.21-.12-.27-.04-.29.16a4 4 0 0 0 0 .49c0 .09.05.16.15.16.08 0 .16-.02.16-.11 0-.43.31-.37.59-.38.12 0 .24-.05.36-.05.74.17 1.02.04 1.38-.67.06-.13.11-.26.23-.36.22-.08.17.06.14.17a3.87 3.87 0 0 1-1.03 2.04c-.11.1-.22.21-.38.24-.37 0-.75.16-1.12-.04.16-.07.33-.1.51-.1.07 0 .13-.03.13-.1 0-.08-.08-.08-.14-.08h-.37c-.22 0-.44.04-.64-.06-.39-.62-.27-1.23.04-1.83.09-.18.23-.34.35-.51Z"
        />
        <Path
          fill="#ee83a4"
          d="m42.31 3.35.05.01c.09.26.24.51.25.8 0 .42.08.85 0 1.26-.26 1.32-.07 2.65-.14 3.98-.02.32-.09.39-.41.41-.71.04-.76-.01-.77-.71V6.23c0-.16-.02-.33.1-.47.06-.38.14-.77-.21-1.07-.08-.07-.12-.14-.09-.24.03-.12.12-.04.18-.04.17-.01.19-.12.19-.26 0-.18.03-.31.25-.3.08 0 .16-.03.17-.11.02-.27.16-.14.3-.08.03-.21-.1-.23-.23-.27 0-.13.16-.07.2-.16.06.04.09.13.19.12Z"
        />
        <Path
          fill="#ee84a3"
          d="M41.39 5.75v3.63c0 .26.08.33.33.32.66-.01.66 0 .66-.66V6.09c0-.13-.04-.28.13-.37.09-.05.04-.19.05-.29.03-.43-.06-.86.05-1.28l.01.17c.04.09.02.18.02.27v5.18c0 .33 0 .33-.34.33h-.96c-.1 0-.21.03-.27-.09l-.01-5.17c.1-.19.11.03.11.04 0 .22.04.44 0 .65-.06.24.05.24.21.23Z"
        />
        <Path
          fill="#ef83a5"
          d="M45.19 6.74c-.13-.21.05-.48-.12-.71-.1.11-.02.23-.08.34-.06-.07-.04-.14-.03-.2.05-.33-.06-.48-.41-.42-.13.02-.22-.03-.21-.18 0-.07-.01-.13-.09-.18-.31-.19-.38-.17-.4.2 0 .14-.11.23-.16.37-.08-.15.03-.32-.09-.44l.03-.25c.1-.06.07-.17.1-.26.03-.08-.04-.21.1-.25.13.15.29.23.48.18.28-.07.55.12.85.03.09-.03.08.12.07.2-.01.31-.06.61.03.92 0 .23.09.47-.09.67Z"
        />
        <Path
          fill="#dc88a1"
          d="M41.08 10.01c.46.13.94.04 1.4.05.19 0 .13-.18.13-.29V4.32c.08.12.06.26.06.39v5.26c0 .05 0 .1-.02.15h-1.58v-.11Z"
        />
        <Path
          fill="#ee86a4"
          d="M43.85 4.77c-.1.08-.06.19-.06.29 0 .11 0 .21-.15.21l.16-.8.24-.56a.47.47 0 0 0 .13-.24l.11-.08c.1.29.12.58.07.88.02.09-.06.12-.11.16-.07.06-.11.21-.23.16-.13-.06-.04-.2-.05-.26 0-.08-.07-.04-.03-.05v.01c.02.11.05.22-.08.29Z"
        />
        <Path
          fill="#ee83a4"
          d="M45.52 4.21a5.4 5.4 0 0 0-.33 1.78c-.1-.33 0-.66-.01-.99-.1 0-.08.1-.1.15-.03.05-.09.1-.11.05-.19-.42-.55-.13-.81-.18-.19-.03-.29-.06-.29-.26.08-.11.05-.25.11-.37l.03.42c.09-.22.21-.3.35-.36.12.08.26.06.39.06.1 0 .21-.02.23.13.02.2.24-.1.26.1.1-.03.04-.11.06-.16l.13-.37c.03-.07.07-.05.11 0Z"
        />
        <Path
          fill="#ee85a3"
          d="M38.61 10.13h-1.54c-.08-.11-.06-.24-.06-.36V3.06c0-.11-.02-.23.06-.33h1.53l.04.12c-.35.11-.71.05-1.06.09-.37.04-.37.01-.37.38v6.22c0 .39 0 .39.39.39h.28c.57 0 .57 0 .57-.59V7.4c-.02-.62.05-1.23.08-1.84 0-.08.04-.13-.1-.13-.08 0-.07-.15-.06-.24.09-.54.08-1.08.09-1.63 0-.21-.03-.46.19-.61l.01 1.91c-.1.17-.05.31.09.43-.16 1.49-.04 2.98-.08 4.47 0 .13.02.27-.06.39Z"
        />
        <Path
          fill="#e59bb2"
          d="M56.57 7.73c-.02-.06.04-.2-.13-.13-.08-.24.02-.44.13-.63.12-.04.13.05.15.13-.05.21-.05.43-.16.63Z"
        />
        <Path
          fill="#e099ac"
          d="m56.72 7.1-.11-.12c-.06-.1-.09-.19.06-.25h.08c.05.06.05.12 0 .19 0 .06-.02.11-.03.17Z"
        />
        <Path fill="#f9d1e3" d="M56.75 6.93v-.19c.19.05.1.2.11.31l-.11-.12Z" />
        <Path fill="#ee85a5" d="M50.76 1.64a.5.5 0 0 1-.19-.32c.19.03.16.2.19.32Z" />
        <Path
          fill="#ee83a4"
          d="M40.58 2.73h.4c.2 0 .41-.05.52.18 0 .44-.03.46-.44.34-.08-.03-.16-.04-.23.01-.14.11.09.16.02.28-.12-.03-.23-.13-.2-.23.08-.22-.05-.39-.08-.59Zm-.61.8c.08-.1-.01-.18-.02-.27 0-.08-.12-.4.21-.22.17-.03.27.06.33.19.05.1.07.22 0 .31s-.16.02-.25 0-.18-.01-.27-.02Zm-.5-.24c.12.01.17-.1.3-.16-.11.24-.14.44.2.41-.23.15-.51.21-.74.38-.07.05-.1-.03-.13-.08.07-.22.19-.41.37-.55Zm2.41-.31.06.06c-.05 0-.11.09-.15.01-.03-.06.04-.07.09-.07ZM39.1 4.2l-.06.05c-.01-.02-.03-.04-.03-.07 0-.01.02-.04.04-.04.04-.01.05.02.05.06Zm-15.42 7.47V4.1c0-.21-.04-.43.29-.39.15.02.14-.12.14-.22 0-.11-.01-.21-.16-.21-.08 0-.16.02-.25-.05.08-.11.19-.07.28-.07.06 0 .13.04.13-.07 0-.1-.06-.08-.12-.07h-.18c-.06 0-.1-.03-.1-.09s.05-.09.1-.09h1.14c.1 0 .11.04.1.13-.05.47.05.94-.07 1.41-.05.2.05.4.07.61 0 .05.03.11.1.09.07-.02.07-.09.07-.15.18.08.1.2.07.32-.17.03-.12.17-.12.27 0 .46-.02.92 0 1.38.02.49-.09.97-.15 1.45-.06.44.01.89.02 1.34-.11.09-.09.22-.09.34v1.9c0 .35-.07.42-.36.44-.4.03-.5-.03-.56-.38-.02-.08-.03-.16-.07-.23-.07-.11 0-.19.05-.28.03-.05.03-.1-.01-.14-.03-.03-.07-.04-.11-.02-.05.03-.04.09-.04.14 0 .08.09.15.01.23-.06.03-.12.02-.17-.03Z"
        />
        <Path
          fill="#ef83a7"
          d="M23.93 11.67c.24.03.19.21.18.35-.03.34.21.26.39.26.18 0 .37.06.36-.25-.02-.69 0-1.38 0-2.06 0-.13-.03-.29.18-.27v2.73c0 .15-.04.17-.16.17h-1.26c.11-.3.03-.62.06-.92h.19c.02-.03.04-.03.06 0Z"
        />
        <Path
          fill="#eb97b0"
          d="M25.28 5.26c0-.11.04-.23-.07-.32.07-.27.11-.55.25-.79.02.06-.04.14.04.19l.09-.06c-.19.52-.28 1.07-.37 1.61 0-.21-.04-.42.05-.63Z"
        />
        <Path
          fill="#ef85a8"
          d="M.18 1.31v8.26c0 .32.1.39.4.39.36 0 .73-.09 1.09.05-.47.03-.94-.05-1.41.05-.12.02-.15-.03-.15-.16L.13.68C.13.56.08.43.19.33c.17.33.12.66 0 .99Z"
        />
        <Path
          fill="#ed85a3"
          d="M.18 1.31V.32c.24.09.49.06.74.06C.67.43.67.61.67.81c0 .56.08.48-.49.49ZM5.06.39c-.1.21-.45.11-.49.43-.03-.5-.38-.43-.68-.43.05-.07.12-.06.2-.06l.98.06Z"
        />
        <Path fill="#ed85a3" d="M5.12 1.25C4.99.97 5.09.67 5.06.39c.13.28.03.58.06.86Z" />
        <Path
          fill="#ef83a6"
          d="M7.77 10.1H7.7c-.05-.14-.17-.09-.26-.09h-.92c-.19 0-.26-.07-.24-.25.03-.02.05-.02.09-.01.35.08.71.16 1.07.1.04-.09-.06-.09-.09-.14-.15-.32-.13-.39.14-.65.08-.08.1-.13-.02-.21-.31-.21-.31-.52 0-.77.11-.09.11-.14-.01-.22-.29-.2-.29-.54 0-.76.12-.09.13-.13 0-.22-.3-.24-.29-.53.02-.77.11-.09.1-.16 0-.21-.3-.14-.21-.41-.22-.65 0-.23-.04-.47.28-.58.14-.05.11-.24-.04-.34-.33-.21-.32-.41.01-.62.15-.1.15-.26 0-.36-.33-.22-.33-.41 0-.62.16-.11.15-.27-.03-.37-.3-.17-.31-.43-.01-.6.2-.12.2-.25.05-.39-.18-.15-.17-.32-.07-.49.05-.1.05-.19.06-.3.02-.17-.04-.25-.22-.24-.1 0-.21-.02-.27-.11V.15q.74 0 .74.73v9.22Z"
        />
        <Path
          fill="#ef7ea4"
          d="M7.03.14V.2a.27.27 0 0 1-.22.09c-.32-.02-.36.18-.36.43v3.45l-.01 5.15c0 .15.04.31-.09.44h-.06V.54q0-.4.4-.4h.34Z"
        />
        <Path
          fill="#ec88a5"
          d="M55.02 6.74c-.18.3-.38.58-.43.93-.05.36.03.69.26.97l-.39.07c-.1-.14-.1-.27.06-.37.03-.39-.1-.79.16-1.22-.24.16-.41.22-.54-.01.16-.25.44-.38.63-.6l.16-.08c.18.07-.01.22.07.32Z"
        />
        <Path
          fill="#ee83a4"
          d="M38.65 2.93c-.08.26-.11.52-.1.78.01.56.01 1.12-.13 1.66.14.07.09-.12.22-.1-.18 1.48-.05 2.96-.12 4.44-.01.29-.1.3-.32.3a10 10 0 0 0-.95.01c-.15.01-.13-.03-.13-.15l.01-6.62-.01-.15c-.11-.24.04-.26.22-.25.44.02.87-.11 1.31 0v.07Z"
        />
        <Path
          fill="#ed91ad"
          d="M60.33 2.73c.14.06.23.16.19.31-.08.26-.11.51.07.77-.25 0-.24-.16-.3-.27-.04-.06-.06-.14-.15-.12-.08.02-.06.09-.07.15-.01.09.03.24-.12.23-.11-.01-.06-.15-.06-.24l.01-.83a.7.7 0 0 1 .43 0Z"
        />
        <Path fill="#eaa2b8" d="M60.33 2.73h-.43c.14-.1.29-.08.43 0Z" />
        <Path
          fill="#eb88a7"
          d="M26.21 3.29v-.12c.31-.31.41.16.68.16-.27.22-.46-.08-.68-.04Zm0 0c0 .1.01.21-.16.22-.01-.13.07-.17.16-.22Z"
        />
        <Path
          fill="#ef83a5"
          d="M12.9 8.41c.1-.01.27.14.26-.12 0-.04.05-.06.09-.05.05.01.07.05.06.09-.03.12-.04.26-.14.33-.16.09-.22.23-.28.44-.1-.22.07-.37 0-.52l.02-.17Z"
        />
        <Path
          fill="#ef81a6"
          d="M47.86 4.46V.4h.06v2.88c0 .15-.05.32.22.25.12-.03.16.07.15.17-.02.14.09.33-.08.42-.14.07-.15-.09-.19-.18-.16.23-.06.5-.11.75-.09-.07-.03-.17-.05-.25Z"
        />
        <Path fill="#ef7fa6" d="M49.33 2.63v4.65h-.03V2.62l.03.01Z" />
        <Path fill="#ef7fa7" d="M49.3 9.25V7.54h.03v1.71h-.03Zm0-6.89V1.15h.02v1.21h-.02Z" />
        <Path fill="#eb91ac" d="M46.68 9.2c.22-.1.39-.27.57-.43-.09.27-.27.42-.57.43Z" />
        <Path fill="#ed86a3" d="m30.44 7.07.07.05c0 .15.17.3 0 .45h-.08a.82.82 0 0 1 0-.49Z" />
        <Path
          fill="#ee84a3"
          d="M54.52 8.34c-.07.11-.06.24-.06.37h-.25c-.18-.02-.27.05-.25.24.03.21-.07.26-.27.26-.21 0-.25-.1-.23-.27.04-.25-.09-.51.08-.74.05-.06.09-.23.2-.03.04.07.16.06.24.12.14.1.22 0 .24-.15 0-.07-.06-.19.05-.2.15-.02.07.13.1.2s.09.13.14.2Z"
        />
        <Path
          fill="#ec85a3"
          d="M23.91 9.24c.11.02.21.04.21.2 0 .14-.06.21-.19.22-.13.01-.18-.05-.19-.18 0-.12.01-.22.16-.24Zm0-2.96c.11.02.21.04.21.2 0 .14-.06.21-.19.22-.13.01-.18-.05-.19-.18 0-.12.01-.22.16-.24Zm-.01-1.97c.15.02.22.07.22.22s-.09.2-.21.2c-.15 0-.17-.11-.17-.23 0-.12.06-.19.15-.19Zm0 1.41c-.11 0-.16-.07-.16-.2s.03-.23.18-.22c.12 0 .2.06.2.21s-.08.19-.21.21Zm.01 1.97c-.15-.01-.17-.11-.16-.24 0-.12.06-.19.18-.18.13.01.19.07.19.22 0 .16-.1.18-.21.2Zm-.01.57c.14.02.22.07.22.21 0 .15-.09.2-.2.2-.15 0-.17-.1-.17-.23 0-.12.05-.19.16-.19Zm.01 1.97c.11.02.21.04.21.2 0 .14-.06.21-.19.22-.13.01-.18-.05-.19-.18 0-.12.01-.22.16-.24Zm.02 1.44h-.06c-.03-.06-.12-.08-.12-.17 0-.1-.04-.21.09-.27.1-.04.22-.02.26.08.04.09.07.22-.07.29a.36.36 0 0 0-.1.07Z"
        />
        <Path
          fill="#ee83a4"
          d="M6.35 9.76V.69c0-.56-.07-.48.49-.49h.18c.05.02.1.03.14.06.12.1.44-.2.41.19-.01.16.11.35-.09.5-.05.04-.14.27.12.34.18.05.09.27.09.41 0 .13-.09.12-.19.12-.16-.01-.17.11-.17.23 0 .12.02.26.17.23.27-.04.19.15.2.27 0 .12.06.29-.19.25-.15-.02-.18.1-.18.22 0 .13.01.27.19.24.25-.03.17.15.17.26 0 .11.07.29-.18.26-.18-.03-.19.12-.19.25 0 .12.03.24.18.22.25-.04.18.14.18.25 0 .11.06.23-.17.28-.41.09-.13.46-.19.69-.04.14-.06.35.2.33.17 0 .09.12.09.18s.08.21-.08.18c-.21-.03-.21.12-.21.24 0 .15-.04.38.14.38.17 0 .18.04.17.16 0 .1.07.22-.17.19-.17-.02-.14.23-.14.38 0 .13.03.28.19.25.24-.05.07.13.12.18-.05.05.12.21-.12.18-.21-.03-.18.19-.18.34 0 .14-.02.33.2.28.15-.03.11.06.1.11-.01.09.07.22-.05.27-.3.12-.32.16-.19.54-.15.11-.32.07-.47.05-.18-.02-.2-.17-.19-.33.02-.27.01-.27-.15-.45-.04.08-.03.16-.03.23-.05.46.02.54.49.56.19 0 .4-.06.54.12-.41.16-.8 0-1.2-.04l-.03-.08Z"
        />
        <Path
          fill="#ed85a3"
          d="M7.58 9.89c-.3-.11-.63-.05-.94-.11-.18-.04-.27-.08-.23-.26.06-.25.05-.5.08-.77.29.19.44.41.31.76-.05.12.05.19.17.2h.43c.14-.01.2.05.18.18Z"
        />
        <Path
          fill="#ee85a3"
          d="M37.44 6.39v3.02c0 .09-.05.22.08.27.1.04.22.08.14.21-.08.12-.23.06-.34.05-.15-.01-.12-.15-.11-.24.02-.13.14-.22.12-.37-.02-.13 0-.27 0-.4 0-.73-.02-1.46 0-2.19.01-.49-.25-.96-.12-1.44.12-.43.09-.86.12-1.29.02-.25-.1-.45-.12-.68-.01-.1-.06-.26.11-.27.18-.01.13.15.13.25v3.08Z"
        />
        <Path
          fill="#fdf5f8"
          d="M60.2 2.86c.12 0 .16.08.16.18 0 .1-.04.18-.15.19-.13.01-.14-.08-.14-.18 0-.09 0-.19.13-.19Z"
        />
        <Path
          fill="#ed84a2"
          d="M6.5 7.2c-.07-.25.02-.5-.07-.74-.03-.07.06-.19.08-.17.11.07.33.03.32.22 0 .17.15.43-.17.5-.09.02-.03.16-.16.2Z"
        />
      </G>
    </Svg>
  )
}

export default FloripondiaLogo
