import React from 'react'
import type { SvgProps } from 'react-native-svg'
import Svg, { G, Path } from 'react-native-svg'

const KoajLogo: React.FC<SvgProps> = (props) => {
  return (
    <Svg data-name="Capa 2" viewBox="0 0 51.72 13.28" {...props}>
      <G data-name="Capa 1">
        <Path d="M20.77 13.28c-1.78-.05-3.49-.36-4.96-1.44a6.1 6.1 0 0 1-2.65-5.7A6.06 6.06 0 0 1 17.18.73a9.34 9.34 0 0 1 7.79.26 6.33 6.33 0 0 1 2.15 9.53 6.7 6.7 0 0 1-4.51 2.58c-.61.1-1.22.13-1.83.19Zm6.24-6.6a4.82 4.82 0 0 0-2.32-4.28 7.16 7.16 0 0 0-6.97-.47 4.86 4.86 0 0 0-3.16 4.34 4.72 4.72 0 0 0 2.26 4.61 7.09 7.09 0 0 0 7.58.2A4.8 4.8 0 0 0 27 6.69Z" />
        <Path d="M13.68 12.97h-1.81c-.12 0-.26-.12-.36-.21L5.04 6.8c-.38-.35-.38-.36-.81-.06-.86.59-1.71 1.19-2.57 1.77a.5.5 0 0 0-.25.5l.01 3.6c0 .32-.11.4-.4.38-.33-.02-.67 0-1.02 0V.34h1.41v6.69l1.14-.78L10.74.57c.24-.17.48-.25.78-.24.6.02 1.2 0 1.83.08L5.81 5.63l7.9 7.27-.03.08Zm14.41 0 .85-1.62L34.6.63c.12-.23.27-.28.52-.31.73-.11 1.11.17 1.46.84 1.92 3.74 3.91 7.45 5.87 11.17l.33.65c-.54 0-1.02.01-1.49-.01-.09 0-.19-.14-.24-.24-.59-1.11-1.17-2.21-1.74-3.33-.12-.24-.26-.33-.54-.33h-6.69c-.21 0-.34.05-.44.26l-1.76 3.36c-.11.22-.25.31-.49.29-.4-.02-.8 0-1.29 0Zm7.35-10.91-3.03 5.77h6.06l-3.03-5.77ZM50.27.35h1.42l.02.28c0 2.79.02 5.58-.02 8.37 0 .66-.14 1.34-.36 1.96-.39 1.1-1.28 1.73-2.38 2.04-1.3.36-2.62.36-3.91-.02-1.78-.53-2.68-1.79-2.72-3.77v-.59h1.42l.04.83c.11 1.44.78 2.2 2.19 2.49.81.16 1.63.14 2.43-.05a2.33 2.33 0 0 0 1.84-2.17c.02-.23.03-.47.03-.7V.36Z" />
      </G>
    </Svg>
  )
}

export default KoajLogo
