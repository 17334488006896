import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const TennisLogo: React.FC<SvgProps> = (props) => (
  <Svg data-name="Capa 2" viewBox="0 0 54.59 18.64" {...props}>
    <G data-name="Capa 1">
      <Path d="M9.41 9.14c-.2 2.6 1.96 4.26 4.24 3.31.46-.19 1.04-.56 1.17-.97.38-1.19 1.2-1.06 2.09-.92.04 1.35-1.08 2.82-2.6 3.41-3.19 1.24-6.23-.46-6.79-3.87-.3-1.82-.16-3.61.9-5.21 1.17-1.78 2.92-2.2 4.9-1.89a4.18 4.18 0 0 1 3.64 3.41c.21.85.2 1.74.31 2.74H9.41Zm5.86-1.56c-.11-2.05-1.2-3.15-3-3.1-1.73.05-2.84 1.23-2.87 3.1h5.88Zm5.7 6.71h-1.9V2.95h1.78v1.44c.92-.46 1.8-1.08 2.79-1.37a3.73 3.73 0 0 1 3.35.52c.59.45 1.27 1.23 1.3 1.89.14 2.93.06 5.87.06 8.86h-1.84v-2.35c0-1.75.03-3.51-.01-5.26-.04-1.45-.72-2.08-2.13-2.1-2.16-.03-3.38 1.14-3.4 3.3-.02 2.11 0 4.22 0 6.4Zm18.88.02h-1.81v-7c-.01-1.94-.56-2.66-2.04-2.72-1.97-.08-3.39.96-3.47 2.68-.09 1.97-.04 3.95-.04 5.92v1.07h-1.9V2.94h1.72V4.4c.94-.47 1.82-1.07 2.8-1.38a4.2 4.2 0 0 1 3.38.46c1.13.66 1.47 1.77 1.48 2.99.01 2.5-.02 5-.04 7.51 0 .08-.03.16-.07.32Zm14.46-8.05c-.46 0-.85.05-1.22-.02-.24-.05-.55-.23-.64-.44-.63-1.41-3.23-1.89-4.29-.77-.58.61-.43 1.63.37 1.92.88.32 1.81.5 2.72.74.32.08.65.15.96.25 1.49.45 2.29 1.28 2.37 2.46.12 1.66-.55 2.86-2.03 3.46-1.63.65-3.3.7-4.93-.06-1.27-.59-1.94-1.81-1.85-3.36.29 0 .59.03.88 0 .55-.06.84.08.96.7.13.69.63 1.15 1.33 1.36 1.05.32 2.07.32 3.06-.21.59-.31.79-.8.75-1.45-.04-.64-.43-.94-.97-1.09-1.3-.38-2.64-.67-3.92-1.12-1.52-.52-2.13-1.7-1.81-3.28.19-.93.76-1.6 1.63-1.97a5.7 5.7 0 0 1 4.76 0c1.17.52 1.79 1.44 1.87 2.86ZM5.52 12.6c.15 1.64.13 1.67-1.34 1.73-1.86.07-2.54-.57-2.55-2.44V4.61H0V2.92h1.58V0h1.93v2.85h2v1.7H3.55c0 2.5-.02 4.91.03 7.32 0 .25.44.59.74.69.34.12.76.03 1.19.03Zm38.28 1.69h-1.83V2.97h1.83V14.3ZM43.82 0v2.02h-1.85V0h1.85Z" />
      <Path
        fill="#1d1d1b"
        d="M44.63 16.93a1.7 1.7 0 0 1-1.73 1.71 1.7 1.7 0 1 1 0-3.4c.97 0 1.73.75 1.73 1.69Zm-3.04 0c0 .75.56 1.35 1.32 1.35s1.29-.6 1.29-1.34-.55-1.36-1.3-1.36-1.31.61-1.31 1.35Zm1.04.89h-.39v-1.69c.15-.03.37-.05.65-.05.32 0 .46.05.59.12.09.07.16.21.16.37 0 .19-.14.33-.35.39v.02c.16.06.26.19.31.41.05.26.08.36.12.42h-.42c-.05-.06-.08-.22-.13-.41-.03-.19-.13-.27-.35-.27h-.19v.68Zm.01-.96h.19c.22 0 .39-.07.39-.25 0-.15-.11-.26-.36-.26l-.22.02v.49Z"
      />
    </G>
  </Svg>
)

export default TennisLogo
