import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const GefLogo: React.FC<SvgProps> = (props) => {
  return (
    <Svg data-name="Capa 2" viewBox="0 0 39.49 26.67" {...props}>
      <G data-name="Capa 1">
        <Path
          fill="#010100"
          d="M3.84 21.19c-.16.4-.2.83-.11 1.24.16.76.73 1.1 1.42 1.25 1.03.23 2.07.21 3.08-.1a1.9 1.9 0 0 0 1.41-1.42c.16-.66-.2-1.37-.83-1.63a4.8 4.8 0 0 0-1.59-.32c-1.09-.06-2.19-.01-3.28-.11-.54-.05-1.07-.13-1.6-.25-1.1-.26-1.5-1.09-1.47-2.12.02-.72.36-1.23.96-1.6.49-.3 1.01-.49 1.61-.66l-.54-.18A3.53 3.53 0 0 1 .43 12c-.1-1.33.2-2.52 1.12-3.52a5.26 5.26 0 0 1 2.7-1.52 8.36 8.36 0 0 1 5.45.41c1.85.8 2.66 2.37 2.6 4.28a4.06 4.06 0 0 1-1.53 3.23c-.76.6-1.65.9-2.59 1.08-.55.11-1.12.07-1.65.27-.4.15-.78.33-1.08.64-.1.11-.22.22-.16.38.06.18.23.23.4.24.31.02.63.02.94.03 1.02.03 2.04-.01 3.06.13a4.3 4.3 0 0 1 3.25 1.95 4.35 4.35 0 0 1-1.8 6.13 8.3 8.3 0 0 1-3.31.87c-1.53.11-3.05.07-4.54-.37a4.51 4.51 0 0 1-2.86-2.09 3.13 3.13 0 0 1-.15-2.84c.04-.1.08-.17.21-.16h3.29l.06.03Zm20.2-5.92h-3.87c-.29 0-.34.09-.31.35.05.43.08.86.21 1.28a2.72 2.72 0 0 0 2.64 1.96 5.6 5.6 0 0 0 3.65-1.25c.17-.13.22-.09.32.06l1.19 1.85c.11.16.09.23-.06.34a8.4 8.4 0 0 1-5 1.78 6.61 6.61 0 0 1-5.1-1.92 6.53 6.53 0 0 1-1.75-3.56c-.32-1.88-.28-3.76.42-5.55a5.68 5.68 0 0 1 4.33-3.78 6.35 6.35 0 0 1 5.27 1.06 5.5 5.5 0 0 1 1.9 3.18c.35 1.36.35 2.75.33 4.14 0 .16-.09.14-.19.14h-3.98v-.08Zm8.08-2.71V4.98c0-.8.06-1.6.31-2.37.5-1.5 1.59-2.29 3.11-2.52.96-.15 1.93-.06 2.9-.07h.86c.14 0 .19.03.19.18v2.54c0 .16-.06.18-.19.17-.51-.02-1.01-.03-1.52-.01-1.2.05-1.79.58-1.86 1.79-.04.68 0 1.36-.02 2.05 0 .18.1.16.21.15h2.82c.19 0 .27.03.26.25-.02.76-.01 1.51 0 2.27 0 .17-.04.22-.21.21H36.3q-.4 0-.4.38v10.95c0 .23-.05.29-.28.28h-3.26c-.22 0-.25-.06-.25-.26v-8.41Z"
        />
        <Path
          fill="#fff"
          d="M13.11 6.44H9.79c-.08-.07-.08-.16-.08-.26V3.42c0-.1 0-.21.09-.28h3.29c.09.07.09.18.09.28v2.73c0 .1 0 .2-.08.28Z"
        />
        <Path
          fill="#e62e2b"
          d="M16.43 3.29v3.1c-.05.04-.11.03-.16.03h-2.9c-.18 0-.24-.05-.23-.23V3.43c0-.2.06-.28.27-.28h2.79c.11 0 .2.02.24.13Z"
        />
        <Path
          fill="#1e5b8a"
          d="m9.81 3.14-.01 3.3c-1.05 0-2.1 0-3.15.02-.15 0-.18-.07-.18-.2v-2.9c0-.17.06-.22.23-.22l3.12.01Z"
        />
        <Path
          fill="#b6403e"
          d="M16.43 3.29c-.08-.09-.18-.1-.3-.1h-2.68c-.21 0-.29.06-.29.28v2.68c0 .19.06.25.25.25h3.01c-.02.05-.07.06-.11.06l-3.21-.01-.01-3.29h3.15c.1 0 .22-.01.19.15Z"
        />
        <Path
          fill="#fff"
          d="M6.44 13.58c-.61-.02-1.22-.09-1.68-.56-.63-.65-.65-1.44-.42-2.24.22-.76.79-1.14 1.54-1.28.42-.08.84-.07 1.26.02.89.19 1.44.73 1.57 1.63.16 1.1-.21 2.08-1.51 2.37-.25.06-.51.09-.77.08Zm16.93-1.05h-3.2c-.16 0-.22-.03-.21-.21.03-.57.11-1.13.34-1.66.41-.92 1.21-1.35 2.27-1.23.83.1 1.47.73 1.65 1.65.09.43.1.88.1 1.32 0 .13-.04.18-.17.17h-.77v-.05Z"
        />
        <Path
          fill="#0e62ad"
          d="M6.53 4.76V3.41c0-.17.06-.22.22-.22h2.74c.2 0 .25.08.25.26v2.71c0 .17-.04.24-.23.24H6.75c-.17 0-.22-.07-.22-.23V4.76Z"
        />
      </G>
    </Svg>
  )
}

export default GefLogo
