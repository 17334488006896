import React from 'react'
import type { SvgProps } from 'react-native-svg'
import Svg, { G, Path } from 'react-native-svg'

const RifleLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 146.9 30.5" {...props}>
    <G data-name="Grupo 9468">
      <Path
        d="m16 19 7.8 11.3h-7.6l-7-10.9h-3v10.9H0V0h12.7c7 0 9.7 4.8 9.7 9.7 0 4.2-2 8.3-6.7 9.4ZM6.2 5.3v9H11c3 .1 5.3-.6 5.4-4.5 0-4-2.5-4.5-5.4-4.5Z"
        data-name="Trazado 38337"
      />
      <Path d="M37.8 30.3 38 .1h6v30.2z" data-name="Rect\xE1ngulo 10058" />
      <Path d="M64 24.7v5.7h-5.9V0h20.5v5.6H64v6.8h12.7v6H64.1Z" data-name="Trazado 38338" />
      <Path d="M91 30.2V.1h6v24.5h14.3v5.7Z" data-name="Trazado 38339" />
      <Path
        d="M131.1 18.5v6.4H147v5.6h-21.7V.2h20.4v5.6h-14.4v6.8H144v5.9Z"
        data-name="Trazado 38340"
      />
    </G>
  </Svg>
)

export default RifleLogo
