import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const EspritLogo: React.FC<SvgProps> = (props) => (
  <Svg data-name="Capa 2" viewBox="0 0 52.2 12" {...props}>
    <G data-name="Capa 1">
      <Path d="M9.7 8H11v.2a3 3 0 0 0 2.4 2.3 5.3 5.3 0 0 0 3.1-.2 2 2 0 0 0 1-2.4c0-.5-.4-.7-.8-1l-1.6-.4c-.2 0-.2 0-.2-.3v-1l.8.2a7 7 0 0 1 1.9.6c.8.5 1.2 1.1 1.3 2.1a3.3 3.3 0 0 1-2.1 3.5l-1.7.4-2-.1a4.4 4.4 0 0 1-3.5-3.7v-.1Z" />
      <Path d="M18.6 3.3H17c-.2-1-.8-1.5-1.6-1.8-.7-.2-1.3-.3-2-.2a3 3 0 0 0-1.3.6c-.4.2-.7.6-.7 1.1-.1.6 0 1.1.5 1.5l1.3.5h.5v1.4h-.3a6 6 0 0 1-1.6-.5c-.6-.3-1.1-.6-1.4-1.2C10 4 10 3.5 10 2.8A3 3 0 0 1 11.7.6 5.6 5.6 0 0 1 16 .3c1.2.4 2 1.2 2.5 2.5v.5ZM33 6.7V5.3h2.7c.4 0 .9-.1 1.2-.4.5-.3.7-.7.8-1.2a1.8 1.8 0 0 0-1.6-2 3 3 0 0 0-.6-.1h-2.4V.2H36c.9 0 1.6.4 2.3 1 .5.5.7 1 .8 1.8a3.1 3.1 0 0 1-2.4 3.5l-1 .1H33Zm-9.8 0V5.3h2.3c.4 0 .8 0 1.2-.3.5-.2.8-.7.9-1.2 0-.6 0-1-.4-1.5a2 2 0 0 0-1.1-.7h-2.9V.2h2.2c.8 0 1.5.2 2.1.6A3 3 0 0 1 29 3c0 .8 0 1.5-.5 2.1-.4.7-1 1.1-1.7 1.4a4 4 0 0 1-1.3.1h-2.2Zm8.6 5h-1.3V.2h1.3v11.5Zm10.5 0H41V.2h1.3v11.5ZM20.6.2H22v11.5h-1.3V.2Zm28 11.5h-1.3V2.8h1.3v9ZM43.7 1.6V.2h8.5v1.4h-8.5ZM0 11.7v-1.3h8.3v1.3H0Zm8.3-6.4v1.3H0V5.5l.1-.2h8.2ZM0 1.6V.2h8.3v1.4H0Zm39.4 10.2h-1.5l-.2-.1-2.5-3.8v-.1h1.4l.2.1 1.5 2.1 1 1.6.1.1Z" />
    </G>
  </Svg>
)

export default EspritLogo
