import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const MICLogo: React.FC<SvgProps> = (props) => (
  <Svg data-name="Capa 2" viewBox="0 0 386.2 386.3" {...props}>
    <G data-name="Capa 1">
      <Path
        fill="#EF8804"
        d="M386.1 193.2a193.2 193.2 0 0 1-32.5 107.2 32.8 32.8 0 0 0-5.2 29.6c2 6.4 3.5 12.8 5.3 19.3l.3 1.2c.6 3-.9 4.2-3.8 3.5l-22.4-6c-9-2.4-17.6-1-25.3 4.3a195 195 0 0 1-90.5 33 191 191 0 0 1-92.5-13.6 193.1 193.1 0 1 1 266.6-178.5ZM74.6 161.7l-.8-3.6c-1.5-5.7-4.8-9.7-10.6-11.3a24.5 24.5 0 0 0-12.6-.2c-6.4 1.6-10 6-11.3 12.3-.4 2-.5 4.3-.5 6.4v61.8c0 2 .1 4 .5 6 1.1 5.4 4 9.8 9 12.5 3.6 2 7.7 2.5 11.8 2.2a18 18 0 0 0 14.8-9.3c2-3.4 2.5-7 2.5-10.9V194c0-2.7.2-5.3.8-8 .6-2.3 1.6-4.5 3.3-6.3 4-4 8.9-4.2 14-3 3.5.8 6 3.1 7.2 6.6a23 23 0 0 1 1.5 7.8c.2 2.2.2 4.3.2 6.5v30.7c.1 9.4 4.8 16.4 12.6 18.7 4.6 1.4 9.2 1.3 13.8-.1a16 16 0 0 0 10.8-10.6c.9-2.6 1.2-5.4 1.2-8.1v-33.5c0-3.5.2-6.9 1.3-10.1 1.4-4.2 4-7.2 8.4-8.1 2.9-.7 5.8-.6 8.6.1a10 10 0 0 1 7 6 21 21 0 0 1 1.6 7.2l.2 6v32.5c0 7.2 2.6 13.2 9 17.1 3.8 2.4 8 2.7 12.5 2.4 3.9-.2 3.9-.2 4.4-4l7.4-52.5c.5-3.5 1.6-7 3.5-10a9.8 9.8 0 0 0 1.4-7.1l-.5-3.5c-2-10-7.3-17.7-16.7-21.9a46.3 46.3 0 0 0-31.8-2c-7.8 2.1-14 6.6-19.5 12.5-.8.9-1.4 2-2.7 2.6-1.2-1.8-2.2-3.7-3.5-5.3a29.8 29.8 0 0 0-20.6-11 38.6 38.6 0 0 0-33.7 11.3c-1.5 1.4-2.8 3-4.5 5Zm230 87.5c2-.1 4.8-.2 7.6-.5 7.4-.7 14.6-2.4 21.2-6 4-2.2 7.6-4.8 10.3-8.6 2.9-4.1 4.2-8.7 3.5-13.7a13.5 13.5 0 0 0-20-10.4c-3.1 1.6-5.5 4-8 6.4a20.6 20.6 0 0 1-13.6 5.8c-8 .4-13.5-3.5-15.6-11.2a55 55 0 0 1-.1-28.9c1.4-5.3 4.5-9.5 10.3-10.9 5.6-1.3 11-.8 15.8 3l4.2 3.5c3 2.6 6 5 9.6 6.6a13 13 0 0 0 18-11.7 21 21 0 0 0-7.3-16.9c-4.6-4.2-10-7-16-9a72.9 72.9 0 0 0-50.8 3.5 6.8 6.8 0 0 0-3.3 3.5c-2.9 6.7-7.5 12-13.9 15.5l-2 1.1c-1.5 1.2-1.7 2.6-.3 4a30.2 30.2 0 0 1 8.2 27l-3.7 26.3a5 5 0 0 0 1.3 4.5 44.9 44.9 0 0 0 17.3 12.4 72.7 72.7 0 0 0 27.4 4.7Zm-79.1 22.1a23.3 23.3 0 0 0 23.2-20.7l7.2-51.6a23 23 0 0 0-29.3-25.8 22.5 22.5 0 0 0-16.8 20l-7.3 51.6a23.3 23.3 0 0 0 23 26.5ZM215 141.2a25.6 25.6 0 1 0 51.1-.2 25.6 25.6 0 0 0-51.1.2Z"
      />
      <Path
        fill="#FEFEFB"
        d="m74.6 161.7 4.4-5a38.6 38.6 0 0 1 33.8-11.2c8.3.9 15.3 4.4 20.6 11 1.3 1.6 2.3 3.5 3.5 5.3 1.2-.6 1.9-1.7 2.7-2.6a40.5 40.5 0 0 1 19.5-12.5 46.4 46.4 0 0 1 31.8 2 28.5 28.5 0 0 1 16.7 21.9l.5 3.5c.4 2.5.1 4.8-1.4 7-2 3-3 6.5-3.5 10.1l-7.4 52.5c-.5 3.7-.5 3.8-4.4 4-4.4.3-8.7 0-12.6-2.4-6.4-3.9-8.8-10-8.9-17.1v-32.5c0-2 0-4-.2-6a21 21 0 0 0-1.6-7.3 10 10 0 0 0-7-5.9c-2.8-.7-5.8-.8-8.7-.1-4.4 1-7 4-8.3 8a30.8 30.8 0 0 0-1.4 10.2V228c0 2.7-.3 5.4-1.2 8a16 16 0 0 1-10.7 10.7 23 23 0 0 1-13.8.1c-7.8-2.3-12.5-9.3-12.6-18.7v-30.7c0-2.2 0-4.4-.2-6.5-.1-2.7-.6-5.3-1.5-7.8a10 10 0 0 0-7.3-6.6c-5-1.2-10-1-13.9 3a14 14 0 0 0-3.3 6.4c-.7 2.6-.8 5.2-.8 7.9v33.7c0 3.8-.6 7.5-2.5 10.9a18 18 0 0 1-14.8 9.2c-4.1.4-8.2 0-11.9-2-5-2.8-7.8-7.2-8.9-12.7-.4-2-.6-4-.6-6v-61.7c0-2.2.2-4.3.6-6.5 1.3-6.3 4.8-10.6 11.3-12.2 4.3-1 8.5-1 12.6.2 5.8 1.6 9 5.6 10.5 11.3l.8 3.6Zm230.1 87.5c-10-.2-19-1.2-27.4-4.7a45 45 0 0 1-17.3-12.4 5.2 5.2 0 0 1-1.3-4.5l3.7-26.2a30.1 30.1 0 0 0-8.2-27.1c-1.4-1.4-1.2-2.8.4-4l1.9-1c6.4-3.7 11-8.9 13.9-15.6a6.7 6.7 0 0 1 3.3-3.5 72.7 72.7 0 0 1 50.8-3.5c6 2 11.4 4.8 16 9 5 4.6 7.6 10 7.2 16.9a13 13 0 0 1-17.9 11.7c-3.7-1.6-6.7-4-9.6-6.6l-4.2-3.5a17.5 17.5 0 0 0-15.8-3c-5.8 1.4-9 5.6-10.3 11a55 55 0 0 0 0 28.8c2.2 7.7 7.7 11.6 15.7 11.2 5.2-.2 9.8-2.1 13.7-5.8 2.4-2.4 4.8-4.8 7.9-6.4 8.8-4.6 18.8.6 20 10.4.7 5-.6 9.6-3.5 13.7a29.7 29.7 0 0 1-10.3 8.5 53.6 53.6 0 0 1-21.2 6.1c-2.8.3-5.7.3-7.5.5Z"
      />
      <Path
        fill="#FEFEFB"
        d="M225.5 271.3c-13.7.2-25-12.4-23-26.5l7.3-51.7c1.5-10.1 7-17 16.8-20A23 23 0 0 1 256 199l-7.3 51.7a23.3 23.3 0 0 1-19.4 20.4l-3.7.3Zm-10.4-130.1a25.5 25.5 0 1 1 51-.3 25.5 25.5 0 0 1-51 .3Z"
      />
    </G>
  </Svg>
)

export default MICLogo
