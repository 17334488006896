import React from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'

const AvemariaLogo: React.FC<SvgProps> = (props) => (
  <Svg data-name="Capa 2" viewBox="0 0 56.55 6.96" {...props}>
    <Path
      d="M28.24.08v6.44c0 .3-.09.39-.38.36-.59-.05-.54.07-.54-.55V2.84c0-.12 0-.24-.07-.38l-.16.3-1.55 3.78c-.07.16-.06.39-.34.36-.29-.03-.56-.03-.7-.38-.37-.97-.78-1.93-1.17-2.89a6.16 6.16 0 0 0-.49-1l-.11 4.24h-.74c-.06 0-.16-.12-.16-.19V.19l.13-.09q.75-.12 1.05.58l1.85 4.34.2.37.96-2.26c.38-.9.77-1.79 1.13-2.69.11-.27.24-.39.53-.35.16.02.33 0 .53 0ZM43.01 4.4l1.47 2.45c-.63.24-1.05.08-1.35-.52-.17-.34-.34-.67-.53-.99a1.16 1.16 0 0 0-1.03-.61h-1.21v1.73c0 .15-.08.41-.15.42-.26.03-.52-.02-.78-.05-.03 0-.05-.14-.05-.21l.04-6.28c0-.18.07-.27.24-.28.97-.02 1.95-.14 2.91.15.82.25 1.36.82 1.51 1.66.16.88.03 1.73-.76 2.33l-.29.23Zm-2.66-.53h1.13l.46-.01a1.36 1.36 0 0 0 1.25-1.26c.08-.69-.23-1.34-.78-1.55-.67-.26-1.37-.16-2.07-.16v2.98Zm-9.24 3.04 1.04-3.04c.39-1.15.81-2.29 1.17-3.45.11-.35.32-.36.58-.38.27-.02.32.2.38.38L35.7 4.6l.68 1.94c.12.34.07.39-.28.39-.58 0-.6 0-.8-.74-.08-.3-.21-.37-.5-.36-.63.02-1.26.04-1.89 0-.38-.02-.55.14-.65.46-.06.19-.09.5-.22.55-.25.1-.56.06-.94.08Zm1.64-1.95h2.14l-1.1-3.18-1.04 3.18Zm18.49 1.88L53.57.11l.05-.02c.59-.03.6-.03.79.51l2.06 5.89c.14.4.09.45-.33.43-.52-.02-.6-.04-.72-.62-.09-.41-.27-.49-.65-.47-.65.03-1.31.02-1.96 0-.2 0-.32.05-.34.26a.3.3 0 0 1-.03.13c-.19.68-.43.81-1.21.62Zm3.7-1.87-1.02-3.31-1.09 3.31h2.11ZM5.06 6.91l-.12.04c-.82.04-.84.04-1.07-.76-.07-.24-.18-.31-.42-.3h-1.9c-.47 0-.54.04-.68.5l-.16.55h-.72c.03-.33-.01-.68.09-.98C.7 4.1 1.36 2.25 1.97.39c.1-.29.28-.25.48-.26.21-.01.33.03.41.26.66 1.96 1.34 3.91 2.02 5.87.07.21.12.42.18.64Zm-1.59-1.9c-.32-1.08-.55-2.16-1.02-3.16h-.09a369.9 369.9 0 0 1-1.01 3.16h2.12ZM15.79.93v2.21h2.72l.05.09c.09.68.08.71-.58.74-.61.03-1.22 0-1.83 0h-.35l.01 1.94c0 .06.18.16.28.17h2.58v.84h-3.8V.14h3.8v.79h-2.89ZM9.22 5.19l1.05-3.02c.21-.62.43-1.23.63-1.85.06-.17.13-.26.32-.24.22.02.44 0 .71 0l-.1.4-2.09 6.06c-.12.35-.33.48-.67.41-.08-.02-.19-.1-.22-.17L6.66.48 6.55.09h.79c.08 0 .18.11.21.19l1.39 4.19.28.72ZM47.44.09h.86v6.77h-.78c-.06 0-.15-.12-.15-.19 0-2.15.02-4.3.04-6.44l.03-.13Z"
      data-name="Capa 2"
    />
  </Svg>
)

export default AvemariaLogo
