import React from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'

const BrissaLogo: React.FC<SvgProps> = (props) => (
  <Svg fill="none" viewBox="0 0 129 35" {...props}>
    <Path
      fill="#4D4E56"
      d="M44.9 9h-3v.4h3v-.5Zm8.4 0h-2.9v.4h3v-.5ZM48 3.4h-.6v3h.5v-3Zm0 8.6h-.6v2.9h.5v-3Zm-2-5.2-1.4-2.5.4-.3 1.4 2.6-.4.2Zm4.3 7.4L49 11.7l.4-.2 1.4 2.5-.4.2ZM45 8l-2.4-1.5.2-.4 2.5 1.4-.2.5Zm7.5 4.2L50 10.8l.2-.5 2.5 1.5-.2.4Zm-7.1-1.4-2.5 1.5-.3-.5 2.5-1.4.3.4Zm7.3-4.3-2.5 1.4-.3-.4L52.5 6l.2.5Zm-6.2 5.2L45 14.3l-.4-.3 1.5-2.5.4.2Zm4.2-7.4-1.4 2.5-.5-.2L50.3 4l.4.3Zm3.3 6.4-.1.2-1 .6h-.1l-.6-1v-.2l1-.6h.1l.6 1Zm-1.7 3H51l-.1-1.2 1.3-.1v1.3Zm-2.9 1.7-1.1-.6.5-1.1h.1l1 .5.1.1-.6 1Zm-3.3.1H46l-.6-1v-.2l1-.6h.1l.6 1v.2l-1 .6Zm-2.9-1.7H43v-1.3h1.2l.1 1.2v.1h-1.2ZM41.4 11v-.2l.6-1 1.1.5v.2l-.5 1h-.1l-1-.6Zm0-3.5 1-.7h.1l.6 1V8l-1 .6H42l-.6-1ZM43 4.6l1.3-.1v1.3H43V4.6Zm2.9-1.8h.1l1 .6v.1l-.5 1-.1.1-1-.6c-.1 0-.1 0 0-.1l.5-1Zm3.5-.4h.1l.6 1v.1l-1 .6H49l-.6-1V3l1-.6Zm2.6 1 .1-.1h1.2l.1 1.2v.1H52V3.4Zm3 1.8v-.1l.7-1h.1l1 .7-.6 1c0 .1-.1.1-.1 0l-1-.6ZM0 22V0h3.3v15a11 11 0 0 1 10.1-5.6c6.8 0 12.5 4.9 12.5 12.8C26 29.6 20.5 35 13 35S0 29.8 0 22Zm22.6.2c0-5.6-4-9.8-9.7-9.8a9.3 9.3 0 0 0-9.6 9.8c0 5.6 3.8 9.7 9.6 9.7s9.7-4 9.7-9.7m48.1-5.6c-.2-2.4-2.4-4.2-6-4.2s-6 1.7-6 4.5c0 6.3 15.9.4 15.9 10.4 0 4.4-3.9 7.7-9.9 7.7s-9.7-3.2-9.9-7.5h3.4c.1 2.6 2.5 4.5 6.5 4.5 4.2 0 6.6-2 6.6-4.7 0-6.3-15.9-.4-15.9-10.4 0-4.4 3.6-7.6 9.3-7.6 5.6 0 9.1 3.2 9.2 7.3h-3.2Zm23.7 0c-.1-2.4-2.3-4.2-6-4.2-3.5 0-6 1.7-6 4.5 0 6.3 16 .4 16 10.4 0 4.4-4 7.7-10 7.7s-9.6-3.2-9.8-7.5H82c.2 2.6 2.5 4.5 6.5 4.5 4.3 0 6.6-2 6.6-4.7 0-6.3-15.8-.4-15.8-10.4 0-4.4 3.6-7.6 9.2-7.6 5.7 0 9.2 3.2 9.3 7.3h-3.3Zm-64.3 18s-2-19 8.9-24.2l1.3 2.8s-8.8 3.8-7.2 21.4h-3Zm19.2-16.4h-3.2v16.4h3.2V18.2Zm79 16.4h-3v-6h-.1a10.7 10.7 0 0 1-10.3 6.4c-6.8 0-12.5-5-12.5-12.9 0-7.4 5.3-12.8 13-12.8 7.6 0 13 5.3 13 13v12.3Zm-3.2-12.5c0-5.6-4-9.7-9.7-9.7a9.3 9.3 0 0 0-9.7 9.7c0 5.7 3.9 9.8 9.7 9.8 5.7 0 9.7-4.1 9.7-9.8Z"
    />
  </Svg>
)

export default BrissaLogo
